import { H1 } from "../common/Typography";

const Vision = () => {
  return (
    <div
      id="vision"
      className="min-h-[70vh] my-10 md:my-5 flex items-center justify-center flex-col"
    >
      <H1>Our Vision</H1>
      <p className=" text-white my-5 w-[700px] max-w-[90%] text-justify md:text-center text-xl leading-9">
        We are building an investment advisor portal powered by Artificial
        Intelligence for personal users. Thereafter, we will offer API licenses
        to our proprietary model for commercial customers. Providing predictive
        analytics services for other domains will be part of the subsequent
        phase. If you are interested in our product, please contact us. Stay
        tuned for the latest updates!
      </p>
    </div>
  );
};

export default Vision;
