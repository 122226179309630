const Footer = () => {
  return (
    <footer className="w-full bg-black pt-[20px]">
      <div className="flex w-full flex-col items-center py-10  mx-auto max-w-[1440px] px-[32px] md:px-[64px] lg:px-[120px]">
        <p className="text-white my-3">
          &copy; 2022 |{" "}
          <strong className="text-yellow">Aarna Analytics LLC</strong>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
