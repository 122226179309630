import { PhoneIllustration } from "../common/images";
import { H1 } from "../common/Typography";

const Product = () => {
  return (
    <div
      id="product"
      className="min-h-[50vh] my-24 flex items-center flex-col-reverse md:flex-row"
    >
      <div className="flex-[0.5] md:mr-36 w-full h-full flex flex-col items-center text-justify leading-7">
        <H1 className="text-center">Profitis</H1>
        <p className="text-white my-4">
          At Aarna Analytics, we have undertaken the greatest challenge there
          is. Predicting the future is a tough task, even with today's
          technology. <br /> <br /> The Profitis Prediction Engine will use
          state of the art methodologies to hyper-tune our models using AI to
          give the best insights possible for a given problem. <br /> <br /> Our
          engine will analyze vast amounts of data and output usable and
          measurable insights that will enable our customers to reach new
          heights. Profitis will be a game changer!
        </p>
        <div className="w-full flex justify-center">
          <a
            href="https://profitis.app"
            className="bg-yellow flex items-center justify-center my-4 p-3 w-64 text-black text-lg tracking-widest transition-all duration-500 ease-in-out rounded-xl hover:bg-black hover:border-[1px] hover:border-yellow hover:text-white"
            target="_blank"
            rel="noreferrer"
          >
            Check it out
          </a>
        </div>
      </div>
      <div className="flex-[0.5] w-full h-full flex flex-col-reverse md:flex-col items-center justify-center">
        <object data={PhoneIllustration} className="flex-[0.5] w-full h-full">
          Illustraion
        </object>
      </div>
    </div>
  );
};

export default Product;
