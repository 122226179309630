import { useEffect, useState } from "react";
import { Logo } from "../images";
import "./index.scss";

const Header = () => {
  const [navStyle, setNavStyle] = useState("");
  const [navOpened, setNavOpened] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", getScrollDetails);

    return () =>
      window.removeEventListener("scroll", () => {
        return;
      });
  }, []);

  const getScrollDetails = () => {
    const top = window.scrollY;
    if (top > 100) setNavStyle("bg-black shadow-md");
    else setNavStyle("");
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full transition-all ease-in duration-300 ${navStyle} z-50`}
    >
      <div
        className={`flex flex-col md:flex-row relative max-w-[1440px] mx-auto px-[32px] md:px-[64px] lg:px-[120px] `}
      >
        <div className={`flex flex-1 items-center ${navOpened ? "mb-10" : ""}`}>
          <a
            href="/"
            className="flex flex-1 md:flex-[0.2px] items-center cursor-pointer text-yellow  text-3xl"
          >
            <img src={Logo} alt="logo" className="w-[120px]" />
          </a>
          <div
            className={`breadcrumb md:hidden px-1  w-[30px] h-[30px] ${
              navOpened ? "opened" : ""
            }`}
            onClick={() => setNavOpened((prevState) => !prevState)}
          >
            <div className={`line lin-1`}></div>
            <div className={`line lin-2`}></div>
            <div className={`line lin-3`}></div>
          </div>
        </div>
        <nav
          className={`md:flex  flex-1  items-center justify-center  ${
            navOpened ? "opacity-[1] navGlass" : "opacity-0 md:opacity-[1]"
          } `}
        >
          <ul
            className={`
            ${
              navOpened
                ? "h-[70vh] opacity-[1]"
                : "h-0 opacity-0 md:opacity-[1]"
            }  w-full transition-all duration-500 ease-in-out flex  flex-col md:flex-row items-center justify-around text-white`}
          >
            <a
              href="#business"
              className="cursor-pointer transition-all duration-300 ease-in-out hover:text-yellow"
            >
              <li>Business</li>
            </a>
            <a
              href="#product"
              className="cursor-pointer transition-all duration-300 ease-in-out hover:text-yellow"
            >
              <li>Product</li>
            </a>
            <a
              href="#vision"
              className="cursor-pointer transition-all duration-300 ease-in-out hover:text-yellow"
            >
              <li>Vision</li>
            </a>
            <a
              href="https://angel.co/company/aarna-analytics/jobs"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer transition-all duration-300 ease-in-out hover:text-yellow"
            >
              <li>Careers</li>
            </a>
            <a
              href="#contact"
              className=" border-[0.5px] flex items-center justify-center border-yellow h-10 w-32 text-white text-center text-md hover:bg-yellow hover:text-black transition-all ease-out duration-300 tracking-widest rounded-xl"
            >
              <button>Contact us</button>
            </a>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
