import { BgYellow, HomeIllustration } from "../common/images";
import "./index.scss";

const Home = () => {
  return (
    <div className="min-h-[90vh] flex items-stretch justify-center relative">
      <img
        src={BgYellow}
        className="absolute w-[500px] h-[500px] -z-10 top-[-10%] left-[-10%] translate-x-[0%] translate-y-[-50%]  "
        alt=""
      />
      <div className="flex-1 flex justify-center flex-col">
        <h1 className="text-white  md:text-[4.5rem] text-[3rem]">Welcome to</h1>
        <h1 className="text-yellow md:text-[5rem]  text-[4rem]">
          Aarna Analytics
        </h1>
        <a
          href="#business"
          className="bg-yellow flex items-center justify-center my-4 p-3 w-64 text-black text-lg tracking-widest transition-all duration-300 ease-out rounded-xl hover:bg-black hover:border-[1px] hover:border-yellow hover:text-white"
        >
          Know more
        </a>
      </div>
      <div className="flex-1 hidden lg:block relative">
        <img
          src={BgYellow}
          className="sm:hidden md:absolute -z-10 right-[-50%] top-[60%] translate-x-[-10%] translate-y-[-50%]"
          alt=""
        />
        <object
          data={HomeIllustration}
          className="block absolute right-[-50%] top-[50%] translate-y-[-50%] w-[600px] h-[600px] xl:w-[800px] xl:h-[800px]"
        >
          Illustraion
        </object>
      </div>
      <a href="#business">
        <div className="z-10 absolute bottom-10 left-1/2 cursor-pointer">
          <div className="w-[22px] h-[37px] border-[3px] transition-all duration-300 ease-out rounded-full hover:border-yellow border-white flex items-start justify-center py-[5px]">
            <span className="h-[10px] border-2 rounded-full hover:border-yellow border-white w-0 landing__scrollKeyframe"></span>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Home;
