import { BusinessIllustraion } from "../common/images";
import { H1 } from "../common/Typography";

const Business = () => {
  return (
    <div
      id="business"
      className="flex my-24 flex-col md:flex-row min-h-screen items-center justify-center"
    >
      <div className="flex-[0.5] w-full h-full flex flex-col-reverse md:flex-col items-center justify-center">
        <object data={BusinessIllustraion} className="flex-[0.5] w-full h-full">
          Illustraion
        </object>
      </div>
      <div className="flex-[0.5] md:ml-36 w-full h-full flex flex-col items-center text-justify leading-7">
        <H1>How We Got Here?</H1>
        <p className="text-white my-4">
          We're a talented group of engineers coming from various industries and
          academia. We all have advanced degrees and numerous years of
          experience in the field of Artificial Intelligence. Our work has been
          published at numerous conferences and journals around the globe.
        </p>
        <p className="text-white my-4">
          ​What unites us and acted as the catalyst to form Aarna Analytics is
          our passion for this subject and our desire to use our expertise to
          create something that will help people make better decisions. We will
          strive to bring solutions to customers in various industries to help
          them make tactical and strategic decisions to overcome the challenges
          they face.
        </p>
        <p className="text-white my-4">
          At Aarna Analytics, we believe that our technological edge and
          expertise can lead our customers towards a successful future. We will
          work to keep our technology ahead of the curve and accessible to
          everyone. <p></p>
        </p>
      </div>
    </div>
  );
};

export default Business;
