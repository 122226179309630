import FeatherIcons from "feather-icons-react";
import { H1 } from "../common/Typography";

const contactInfo = [
  {
    icon: "map-pin",
    text: "McKinney, TX, USA",
    link: `https://www.google.com/maps/place/McKinney, TX, USA`,
    external: true,
  },
  {
    icon: "mail",
    text: "contact@aarnaanalytics.com",
    link: "mailto:contact@aarnaanalytics.com",
    external: false,
  },
  // {
  //   icon: "phone",
  //   text: "(214)856-0385",
  //   link: "tel:(214)856-0385",
  //   external: false,
  // },
  {
    icon: "linkedin",
    text: "Aarna Analytics",
    link: `https://www.linkedin.com/company/aarna-analytics/`,
    external: true,
  },
];

const Contact = () => {
  return (
    <div
      id="contact"
      className=" min-h-[50vh]   flex items-center justify-center flex-col"
    >
      <H1>Get in touch!</H1>
      <div className="w-full py-10   md:my-10 flex items-center justify-evenly flex-wrap">
        {contactInfo?.map((contact, id) => (
          <a
            href={contact?.link}
            key={id}
            target={contact?.external ? "_blank" : ""}
            rel="noreferrer"
          >
            <div className="w-[270px] h-[125px] mx-3 my-5 ease-in-out bg-[#302D2D] rounded-[15px]  cursor-pointer flex flex-col items-center justify-center  transition-all duration-500 ">
              <FeatherIcons
                fill="#302D2D"
                stroke="#FFC668"
                className="flex-[0.6] w-8"
                icon={contact?.icon}
              />
              <p className="flex-[0.4] text-white">{contact?.text}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Contact;
