const H1 = ({ className, children }) => {
  return (
    <h1
      className={`text-yellow  my-10 text-[2.5rem] md:text-[3rem] text-center font-medium ${className} leading-[3rem] `}
    >
      {children}
    </h1>
  );
};

export default H1;
