import Footer from "../Footer";
import Header from "../Header";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col overflow-x-hidden ">
      <Header />
      <main className="w-full mt-[80px] mb-[100px] mx-auto max-w-[1440px] px-[32px] md:px-[64px] lg:px-[120px]">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
