import Contact from "./presentation/Contact";
import Home from "./presentation/Home";
import Layout from "./presentation/common/Layout";
import Product from "./presentation/Product";
import Business from "./presentation/Business";
import Vision from "./presentation/Vision";

const App = () => {
  return (
    <Layout>
      <Home />
      <Business />
      <Product />
      <Vision />
      <Contact />
    </Layout>
  );
};

export default App;
